<template>
  <b-container>
    <b-form v-on:submit.prevent>
      <b-row align-h="center">
        <b-col cols="6">
          <b-card>
            <label for="items" class="text-primary font-weight-bolder mb-1"
              >Detail</label
            >
            <b-row>
              <b-col>
                <b-form-group
                  :state="errors"
                  label="Gambar tema"
                  label-for="Gambar Tema"
                  invalid-feedback="Gambar tema tidak boleh kosong"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Foto"
                    rules="required"
                  >
                    <b-img
                      id="photoURL"
                      :src="photo"
                      fluid
                      alt="product photo"
                      class="mb-1"
                      v-if="(hasImage === '') | null"
                    />
                    <image-uploader
                      :preview="true"
                      :className="[
                        'fileinput',
                        { 'fileinput--loaded': hasImage },
                      ]"
                      :debug="0"
                      :autoRotate="true"
                      accept="image/*"
                      doNotResize="['gif', 'svg']"
                      outputFormat="string"
                      @input="setImage"
                    ></image-uploader>
                    <small class="text-danger">{{
                      errors[0] || errMessageImage
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Status" label-for="status">
                  <b-form-select
                    id="status"
                    v-model="status"
                    :options="statusOpt"
                    required
                  />
                  <!-- Selected: <strong>{{ form.selectedCategory }}</strong> -->
                </b-form-group>
              </b-col>
            </b-row>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mt-2"
              @click="postData()"
            >
              Kirim
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import {
  BImg,
  BFormFile,
  BFormSelect,
  BListGroupItem,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BContainer,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ImageUploader from "vue-image-upload-resize";
import { $themeConfig } from "@themeConfig";
import Cleave from "vue-cleave-component";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    quillEditor,
    ToastificationContent,
    ImageUploader,
    Cleave,
    ValidationProvider,
    ValidationObserver,
    BImg,
    BFormFile,
    BFormSelect,
    BListGroupItem,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    Prism,
    BContainer,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        image: "",
      },
      image: "",
      photo: "",
      hasImage: "",
      status: 0,
      errors: "",
      errMessage: "",
      errMessageImage: "",
      statusOpt: [
        {
          value: 0,
          text: "Non-aktif",
        },
        {
          value: 1,
          text: "Aktif",
        },
      ],
    };
  },
  setup() {
    // App Name
    const { baseUrl, apiUrl } = $themeConfig.app;
    return {
      baseUrl,
      apiUrl,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      const { baseUrl } = $themeConfig.app;
      const imageUri = baseUrl + "image/theme/";

      this.$http
        .get("theme")
        .then((response) => {
          this.photo = imageUri + response.data.data.image;
          this.image = this.getBase64Image(document.getElementById("photoURL"));
          this.status = response.data.data.status;
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    postData() {
      this.$http
        .post("theme/add", {
          image: this.image,
          status: this.status,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Gambar Tema berhasil diupdate",
              variant: "success",
            },
          });
          setTimeout(() => {
            this.$router.push("/");
          }, 2000);
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    getBase64Image(img) {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      this.image = canvas.toDataURL("image/png");
      this.image += this.image.replace(/^data:image\/(png|jpg);base64,/, "");
    },
    setImage: function (file) {
      this.hasImage = true;
      this.image = file;
    },
  },
};
</script>
